.header {
    display: flex;
    justify-content: space-between;
    background-color: rgb(29, 27, 30);
    width: 100%;
    align-items: center;
    z-index: 9999;
    height: 50px;
  }
  
  .logo-container {
    display: flex;
    align-items: center;
  }
  
  .logo {
    height: 40px;
  }
  
  .header-buttons {
    display: flex;
    align-items: center;
  }
  
  .toggle-btn-content {
    display: flex;
  }
  
  .toggle-btn-text {
    display: inline-block;
  }
  
  @media (max-width: 768px) {
    .header {
      justify-content: space-around;
      flex-wrap: nowrap;
      overflow: hidden;
    }
  
    .logo {
      height: 40px;
    }
  
    .header-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  
    .header-buttons > div {
      display: flex;
    }
  
    .toggle-btn-icon {
      display: inline-block;
    }
  
    .toggle-btn-text {
      display: inline-block;
    }
  }
  