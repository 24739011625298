.message {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
}

.user {
    align-self: flex-start;
    color: white;
}

.bot {
    align-self: flex-start;
    color: white;
}
