.profile-card {
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; 
    border: 1px solid #000000;
    transition: background-color 0.3s;
  }
  
  .profile-card:hover {
    background-color: #f0f0f0;
  }
  
  .card-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left; 
    padding: 30px;
  }
  
  .card-icon {
    font-size: 48px;
    justify-content: center;
    align-items: center;
    text-align: center; 
  }
  
  .ant-card .ant-card-cover >* {
    display: flex;
  }