.read-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 50px);
  width: calc(100vw - 50px);
  position: relative;
}

.phone-screen {
  width: 375px;
  height: 635px;
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgb(77, 67, 87);
  overflow: hidden;
  position: relative;
  color: white;
  font-family: 'IBM Plex Mono', monospace;
  text-align: left;
  padding: 8px;
}

.social-buttons {
  display: flex;
  align-items: center;
  padding-top: 20px;
  position: absolute;
  bottom: 18px;
  left: 190px;
}

.phone-screen-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
}

.expand-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1;
}

.phone-screen {
  padding: 2rem;
}

.full-screen {
  margin-top: 1rem;
  width: 100%;
  height: 100%;
  padding: 3rem;
  box-sizing: border-box
}

.expand-button-container {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .read-page-container {
    background-color: rgb(77, 67, 87);
  }

  .phone-screen {
    border-radius: 0px;
    overflow: hidden;
  }

  .right-buttons-mobile {
    flex-direction: row;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  .expand-button {
    display: none;
  }
}