.image {
  border-top: 3px solid black;
  .image-slider {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    overflow: hidden;
    border-right: 3px solid black;
    padding-top: 4.5vh;
    padding-bottom: 4.5vh;
    overflow: hidden;
    .image-section {
      flex: 1;
      display: flex;
      align-items: center;
      height: 78vh;
      border-radius: 30px;
      border: 2px solid rgb(110, 110, 110);
      box-shadow: rgba(23, 23, 23, 0.718) 0px 2px 8px 0px;
      overflow: hidden;
      margin: 10px;
      .image-wrapper {
        padding-left: 220px;
  box-sizing: border-box;
  overflow-x: hidden;
        img {
          overflow: hidden;
          border-radius: 30px;
          box-shadow: rgba(23, 23, 23, 0.718) 0px 2px 8px 0px;
        }
      }
    }

    .button-container {
      max-width: 340px;
      padding-left: 20px;
      padding-right: 20px;
      h1 {
        max-width: 255px;
        margin: 0 auto;
        font-size: 40px;
        padding-bottom: 18px;
      }

      .button-section {
        display: flex;
        flex-direction: column;
        height: 60vh;
        justify-content: space-between;
        margin: 0 auto;

        .custom-button {
          // Add button styles here
        }
      }
    }
  }
}


@media screen and (max-width: 1000px) {

  .image {
    border-top: 3px solid black;
    .image-slider {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      overflow: hidden;
      border-right: 3px solid black;
      padding-top: 4.5vh;
      padding-bottom: 4.5vh;
      overflow: hidden;
      .image-section {
        flex: 1;
        display: flex;
        align-items: center;
        height: 78vh;
        border-radius: 30px;
        border: 2px solid rgb(110, 110, 110);
        box-shadow: rgba(23, 23, 23, 0.718) 0px 2px 8px 0px;
        overflow: hidden;
        margin: 10px;
        .image-wrapper {
          padding-left: 120px;
    box-sizing: border-box;
    overflow-x: hidden;
          img {
            overflow: hidden;
            border-radius: 30px;
            box-shadow: rgba(23, 23, 23, 0.718) 0px 2px 8px 0px;
          }
        }
      }
  
      .button-container {
        max-width: 340px;
        padding-left: 20px;
        padding-right: 20px;
        h1 {
          max-width: 255px;
          margin: 0 auto;
          font-size: 40px;
          padding-bottom: 18px;
        }
  
        .button-section {
          display: flex;
          flex-direction: column;
          height: 60vh;
          justify-content: space-between;
          margin: 0 auto;
  
          .custom-button {
            // Add button styles here
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {

  .image {
    border-top: 3px solid black;
    .image-slider {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      overflow: hidden;
      border-right: 3px solid black;
      padding-top: 4.5vh;
      padding-bottom: 4.5vh;
      overflow: hidden;
      .image-section {
        flex: 1;
        display: flex;
        align-items: center;
        height: 78vh;
        border-radius: 30px;
        border: 2px solid rgb(110, 110, 110);
        box-shadow: rgba(23, 23, 23, 0.718) 0px 2px 8px 0px;
        overflow: hidden;
        margin: 10px;
        .image-wrapper {
          padding-left: 50px;
    box-sizing: border-box;
    overflow-x: hidden;
          img {
            overflow: hidden;
            border-radius: 30px;
            box-shadow: rgba(23, 23, 23, 0.718) 0px 2px 8px 0px;
          }
        }
      }
  
      .button-container {
        max-width: 340px;
        padding-left: 5px;
        padding-right: 5px;
        h1 {
          max-width: 255px;
          margin: 0 auto;
          font-size: 40px;
          padding-bottom: 18px;
        }
  
        .button-section {
          display: flex;
          flex-direction: column;
          height: 60vh;
          justify-content: space-between;
          margin: 0 auto;
  
          .custom-button {
            // Add button styles here
          }
        }
      }
    }
  }
}

@media screen and (max-width:700px) {

  .image {
    border-top: 3px solid black;
    .image-slider {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      overflow: hidden;
      border-right: 3px solid black;
      padding-top: 4.5vh;
      padding-bottom: 4.5vh;
      overflow: hidden;
      .image-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 78vh;
        border-radius: 30px;
        border: 2px solid rgb(110, 110, 110);
        box-shadow: rgba(23, 23, 23, 0.718) 0px 2px 8px 0px;
        overflow: hidden;
        margin: 10px;
        .image-wrapper {
          padding-left: 0px;
    box-sizing: border-box;
    overflow-x: hidden;
          img {
            overflow: hidden;
            border-radius: 30px;
            box-shadow: rgba(23, 23, 23, 0.718) 0px 2px 8px 0px;
          }
        }
      }
  
      .button-container {
        max-width: 340px;
        padding-left: 5px;
        padding-right: 5px;
        h1 {
          max-width: 255px;
          margin: 0 auto;
          font-size: 40px;
          padding-bottom: 18px;
        }
  
        .button-section {
          display: flex;
          flex-direction: column;
          height: 60vh;
          justify-content: space-between;
          margin: 0 auto;
  
          .custom-button {
            // Add button styles here
          }
        }
      }
    }
  }
}

@media screen and (max-width:600px) {

  .image {
    border-top: 3px solid black;
    .image-slider {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      border-right: 3px solid black;
      padding-top: 4.5vh;
      padding-bottom: 4.5vh;
      overflow: hidden;
      .image-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 78vh;
        border-radius: 30px;
        border: 2px solid rgb(110, 110, 110);
        box-shadow: rgba(23, 23, 23, 0.718) 0px 2px 8px 0px;
        overflow: hidden;
        margin: 10px;
        .image-wrapper {
          padding-left: 0px;
    box-sizing: border-box;
    overflow-x: hidden;
          img {
            overflow: hidden;
            border-radius: 30px;
            box-shadow: rgba(23, 23, 23, 0.718) 0px 2px 8px 0px;
          }
        }
      }
  
      .button-container {
        margin-top: 20px;
        max-width: 340px;
        padding-left: 100px;
        padding-right: 5px;
        h1 {
          max-width: 255px;
          margin: 0 auto;
          font-size: 40px;
          padding-bottom: 18px;
        }
  
        .button-section {
          display: flex;
          flex-direction: column;
          height: 60vh;
          justify-content: space-between;
          margin: 0 auto;
  
          .custom-button {
            // Add button styles here
          }
        }
      }
    }
  }
}

@media screen and (max-width:500px) {

  .image {
    border-top: 3px solid black;
    .image-slider {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      border-right: 3px solid black;
      padding-top: 4.5vh;
      padding-bottom: 4.5vh;
      overflow: hidden;
      .image-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 78vh;
        border-radius: 30px;
        border: 2px solid rgb(110, 110, 110);
        box-shadow: rgba(23, 23, 23, 0.718) 0px 2px 8px 0px;
        overflow: hidden;
        margin: 10px;
        .image-wrapper {
          padding-left: 0px;
    box-sizing: border-box;
    overflow-x: hidden;
          img {
            overflow: hidden;
            border-radius: 30px;
            box-shadow: rgba(23, 23, 23, 0.718) 0px 2px 8px 0px;
          }
        }
      }
  
      .button-container {
        margin-top: 20px;
        max-width: 340px;
        padding-left: 40px;
        padding-right: 5px;
        h1 {
          max-width: 255px;
          margin: 0 auto;
          font-size: 40px;
          padding-bottom: 18px;
        }
  
        .button-section {
          display: flex;
          flex-direction: column;
          height: 60vh;
          justify-content: space-between;
          margin: 0 auto;
  
          .custom-button {
            // Add button styles here
          }
        }
      }
    }
  }
}

@media screen and (max-width:400px) {

  .image {
    border-top: 3px solid black;
    .image-slider {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      border-right: 3px solid black;
      padding-top: 4.5vh;
      padding-bottom: 4.5vh;
      overflow: hidden;
      .image-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 78vh;
        border-radius: 30px;
        border: 2px solid rgb(110, 110, 110);
        box-shadow: rgba(23, 23, 23, 0.718) 0px 2px 8px 0px;
        overflow: hidden;
        margin: 10px;
        .image-wrapper {
          padding-left: 0px;
    box-sizing: border-box;
    overflow-x: hidden;
          img {
            overflow: hidden;
            border-radius: 30px;
            box-shadow: rgba(23, 23, 23, 0.718) 0px 2px 8px 0px;
          }
        }
      }
  
      .button-container {
        margin-top: 20px;
        max-width: 340px;
        padding-left: 10px;
        padding-right: 10px;
        h1 {
          max-width: 255px;
          margin: 0 auto;
          font-size: 40px;
          padding-bottom: 18px;
        }
  
        .button-section {
          display: flex;
          flex-direction: column;
          height: 60vh;
          justify-content: space-between;
          margin: 0 auto;
  
          .custom-button {
            // Add button styles here
          }
        }
      }
    }
  }
}













