.footer {
    text-align: center; 
    background: #f7f7f7; 
    color: #050505; 
    width: 100%;
    position: absolute;
    bottom: 0;
}

.footer-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.footer-link {
    color: #61dafb;
    margin-left: 10px;
}
