.outer-container {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    border-right: 3px solid black;
    .container {
      max-width: 1200px;
      height: 100vh;
      margin: 0 auto;
      display: flex;
      > div {
        flex: 1;
        display: flex;
        align-items: center;
        margin: 10px;
      }
    }
  
    .logo {
      img {
        max-width: 155px;
        width: 100%;
        height: auto;
        transform: rotate(-20deg);
        margin-top: 150px;
      }
    }
  
    .image-container {
      height: 85vh;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 7vh;
      overflow: hidden;
      max-width: 400px;
  
      img {
        border-radius: 30px;
        display: none;
        max-height: 80vh;
        object-fit: contain;
      }
  
      &.isOn img {
        display: block;
      }
  
      &.isOff img {
        display: block;
      }
    }
  
    .button-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;
  
      h1 {
        font-size: 38px;
        text-align: center;
        margin-top: 40px;
      }
  
      .switch {
        position: relative;
        width: 80px;
        height: 40px;
        -webkit-appearance: none;
        appearance: none;
        background-color: black;
        outline: none;
        border-radius: 2rem;
        cursor: pointer;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  
        &::before {
          content: '';
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: #fff;
          position: absolute;
          top: 0;
          left: 0;
          transition: 0.5s;
        }
  
        &:checked::before {
          transform: translateX(100%);
          background: #fff;
        }
      }
    }
  
    .absolute-image {
      position: absolute;
      top: 0;
      left: 0;
      right: auto;
      transition: left 0.5s ease-in-out;
  
      &.isImage2 {
        left: auto;
        right: 0;
      }
    }
  }
  

  @media screen and (max-width: 800px){
    .outer-container {
      max-width: 1200px;
      margin: 0 auto;
      position: relative;
      border-right: 3px solid black;
      .container {
        max-width: 1200px;
        height: auto;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        > div {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 10px;
        }
      }
    
      .logo {
        img {
          max-width: 155px;
          width: 100%;
          height: auto;
          transform: rotate(-20deg);
          margin-top:30px;
        }
      }
    
      .image-container {
        height: 85vh;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 7vh;
        overflow: hidden;
        width: 100%;
        max-width: 750px;
        margin: 0 auto;
        img {
          border-radius: 30px;
          display: none;
          max-height: 80vh;
          object-fit: contain;
          max-width: 400px;
          margin: 0 auto;
        }
    
        &.isOn img {
          display: block;
        }
    
        &.isOff img {
          display: block;
        }
      }
    
      .button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
        padding-top: 20px;
        h1 {
          font-size: 38px;
          text-align: center;
          margin-top: 20px;
        }
    
        .switch {
          position: relative;
          width: 80px;
          height: 40px;
          -webkit-appearance: none;
          appearance: none;
          background-color: black;
          outline: none;
          border-radius: 2rem;
          cursor: pointer;
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    
          &::before {
            content: '';
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #fff;
            position: absolute;
            top: 0;
            left: 0;
            transition: 0.5s;
          }
    
          &:checked::before {
            transform: translateX(100%);
            background: #fff;
          }
        }
      }
    
      .absolute-image {
        position: absolute;
        top: 0;
        left: 0;
        right: auto;
        transition: left 0.5s ease-in-out;
    
        &.isImage2 {
          left: auto;
          right: 0;
        }
      }
    }
    
  }

  @media screen and (max-width: 500px){
    .outer-container {
      max-width: 1200px;
      margin: 0 auto;
      position: relative;
      border-right: 3px solid black;
      .container {
        max-width: 1200px;
        height: auto;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        > div {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 10px;
        }
      }
    
      .logo {
        img {
          max-width: 155px;
          width: 100%;
          height: auto;
          transform: rotate(-20deg);
          margin-top:30px;
        }
      }
    
      .image-container {
        height: 85vh;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 7vh;
        overflow: hidden;
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        img {
          border-radius: 30px;
          display: none;
          max-height: 80vh;
          object-fit: contain;
          max-width: 350px;
          margin: 0 auto;
        }
    
        &.isOn img {
          display: block;
        }
    
        &.isOff img {
          display: block;
        }
      }
    
      .button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
        padding-top: 20px;
        h1 {
          font-size: 38px;
          text-align: center;
          margin-top: 20px;
        }
    
        .switch {
          position: relative;
          width: 80px;
          height: 40px;
          -webkit-appearance: none;
          appearance: none;
          background-color: black;
          outline: none;
          border-radius: 2rem;
          cursor: pointer;
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    
          &::before {
            content: '';
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #fff;
            position: absolute;
            top: 0;
            left: 0;
            transition: 0.5s;
          }
    
          &:checked::before {
            transform: translateX(100%);
            background: #fff;
          }
        }
      }
    
      .absolute-image {
        position: absolute;
        top: 0;
        left: 0;
        right: auto;
        transition: left 0.5s ease-in-out;
    
        &.isImage2 {
          left: auto;
          right: 0;
        }
      }
    }
    
  }