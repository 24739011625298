.App {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  background-color: #f7f7f7;
}

.App.ProfilePage {
  justify-content: flex-start;
}
