.background {
  position: absolute;
  width: 100%;
  height: calc(100% - 50px);
  background-image: radial-gradient(farthest-corner at 40px 40px, rgb(54, 44, 63) 85%, rgb(71, 12, 122, 0.9) 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 30px;
  box-sizing: border-box;
  overflow: hidden;
}

.message-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 20px;
  padding-top: 0px;
  padding-bottom: 0;
  border: 1px solid rgba(255, 255, 255);
  border-radius: 15px;
}

.input-container {
  display: flex;
  width: 100%;
  margin-top: 20px;
}

.input-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  font-size: 18px;
}

.input-field input {
  flex-grow: 1;
  border: none;
  background: transparent;
  font-size: 20px;
  color: white;
  outline: none;
  width: 100%;
}

.icon-button {
  border: none;
  background: transparent;
  color: white;
  cursor: pointer;
  margin-right: 10px;
}

.send-button {
  margin-left: 10px;
}

.right-button {
  margin-top: 75px;
  text-align: right;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent; /* Changed this from #d6dee1 to transparent */
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.avatarScroll:hover::-webkit-scrollbar-thumb {
  background-color: #a8bbbf;
}

.avatarScroll {
  overflow-x: scroll; /* Changed this from hidden to scroll */
  display: flex;
  flex: 1;
  flex-direction: row; 
  height: 120px;
  justify-content: left;
  align-items: center;
}

::placeholder {
  color: var(--placeholder-color);
  font-family: var(--placeholder--font);
}