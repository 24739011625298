/* .Write {
  text-align: center;
}

.Write-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Write-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Write-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Write-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

} */

.background-light {
    position: absolute;
    width: 100%;
    height: calc(100% - 50px);
    background-image: radial-gradient(farthest-corner at 40px 40px, #e5e2d1 0%, rgb(95, 43, 146, 0.5) 100%);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 80px;
    box-sizing: border-box;
    overflow: hidden;
  }
  
  .message-container-light {
    background: rgb(255, 255, 255, 0.5);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: auto;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
  }
  
  .input-container {
    display: flex;
    width: 100%;
    margin-top: 20px;
  }
  
  .input-field input {
    flex-grow: 1;
    border: none;
    background: transparent;
    font-size: 24px;
    color: white;
    outline: none;
    width: 100%;
  }
  