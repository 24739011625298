.home{
    width: 100%;
    height: 100vh;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    border-right: 3px solid black;
    .home__relative{
        position: relative;
        .home__inner{
            margin-top: 4vh;
            display: flex;
            padding: 10px;
            .container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 85vh;
                border-radius: 23px;
                border: 3px solid black;
              }
              .content {
                align-items: center;
                margin-top: auto;
                margin-bottom: auto;
                h1{
                    font-size: 35px;
                    padding-left: 40px;
                    padding-right: 90px;
                    font-weight: bold;
                    font-weight: 900;
                }
              }
              .bottom-button {
                background-color: black;
                font-size: 30px;
                color: white;
                font-weight: bold;
                height: 90px;
                border-radius: 0px 0px 17px 17px;
                border: 3px solid black;
                cursor: pointer;
                /* Add any desired styles to the button */
              }
              .bottom-button:hover{
                background-color: rgb(13, 13, 13);
              }
        }
        .mobile-vibrate{
            position: absolute;
            top: 49%;
            right: 6px;
            transform: translateY(-50%);
            display: flex;
            .iphone{
                img{
                    width: 260px;
                }
            }
            .lines{
                img{
                    width: 400px;
                    margin-top: 150px;
                    margin-left: -4px;
                }
            }
        }
    }
    .second{
        h1{
            color: rgb(247, 247, 247);
        }
    } 
}


@media screen and (max-width: 1200px){
    .home{
      margin-right: 100px !important;
    }
  }
@media screen and (max-width: 1150px) {
    .home{
        .home__relative{
            .home__inner{
                .container {
                  }
                  .content {
                    h1{
                        font-size: 35px;
                    }
                  }
                  .bottom-button {
                    font-size: 30px;
                    height: 90px;
                    /* Add any desired styles to the button */
                  }
            }
            .mobile-vibrate{
                top: 49%;
                right: 7px;
                transform: translateY(-50%);
                display: flex;
                .iphone{
                    img{
                        width: 260px;
                    }
                }
                .lines{
                    img{
                        width: 350px;
                        margin-top: 150px;
                    }
                }
            }
        } 
    }
}

@media screen and (max-width: 1050px) {
    .home{
        .home__relative{
            .home__inner{
                .container {
                  }
                  .content {
                    h1{
                        font-size: 30px;
                    }
                  }
                  .bottom-button {
                    font-size: 23px;
                    height: 80px;
                    /* Add any desired styles to the button */
                  }
            }
            .mobile-vibrate{
                top: 49%;
                right: 7px;
                transform: translateY(-50%);
                display: flex;
                .iphone{
                    img{
                        width: 260px;
                    }
                }
                .lines{
                    img{
                        width: 275px;
                        margin-top: 150px;
                    }
                }
            }
        } 
    }
}
@media screen and (max-width: 870px) {
    .home{
        .home__relative{
            .home__inner{
                .container {
                  }
                  .content {
                    h1{
                        font-size: 27px;
                    }
                  }
                  .bottom-button {
                    font-size: 23px;
                    height: 80px;
                    /* Add any desired styles to the button */
                  }
            }
            .mobile-vibrate{
                top: 49%;
                right: 7px;
                transform: translateY(-50%);
                display: flex;
                .iphone{
                    img{
                        width: 260px;
                    }
                }
                .lines{
                    img{
                        width: 200px;
                        margin-top: 150px;
                    }
                }
            }
        } 
    }
}

@media screen and (max-width: 750px) {
    .home{
        height: auto;
        .home__relative{
            .home__inner{
                display: flex;
                flex-direction: column;
                .container {
                  }
                  .sec{
                    margin-top: 20px;
                  }
                  .content {
                    h1{
                        font-size: 37px;
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                  }
                  .bottom-button {
                    font-size: 23px;
                    height: 80px;
                    /* Add any desired styles to the button */
                  }
            }
            .mobile-vibrate{
                top: 76%;
                right: 7px;
                transform: translateY(-50%);
                display: flex;
                .iphone{
                    img{
                        width: 260px;
                    }
                }
                .lines{
                    img{
                        width: 380px;
                        margin-top: 150px;
                    }
                }
            }
        } 
    }
}

@media screen and (max-width: 667px) {
    .home{
        height: auto;
        .home__relative{
            .home__inner{
                display: flex;
                flex-direction: column;
                .container {
                  }
                  .content {
                    h1{
                        font-size: 37px;
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                  }
                  .bottom-button {
                    font-size: 23px;
                    height: 80px;
                    /* Add any desired styles to the button */
                  }
            }
            .mobile-vibrate{
                top: 76%;
                right: 7px;
                transform: translateY(-50%);
                display: flex;
                .iphone{
                    img{
                        width: 260px;
                    }
                }
                .lines{
                    img{
                        width: 300px;
                        margin-top: 150px;
                    }
                }
            }
        } 
    }
}

@media screen and (max-width: 580px) {
    .home{
        height: auto;
        .home__relative{
            .home__inner{
                display: flex;
                flex-direction: column;
                .container {
                  }
                  .content {
                    h1{
                        font-size: 37px;
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                  }
                  .bottom-button {
                    font-size: 23px;
                    height: 80px;
                    /* Add any desired styles to the button */
                  }
            }
            .mobile-vibrate{
                top: 76%;
                right: 8px;
                transform: translateY(-50%);
                display: flex;
                .iphone{
                    img{
                        width: 260px;
                    }
                }
                .lines{
                    img{
                        width: 240px;
                        margin-top: 150px;
                    }
                }
            }
        } 
    }
}

@media screen and (max-width: 520px) {
    .home{
        height: auto;
        .home__relative{
            .home__inner{
                display: flex;
                flex-direction: column;
                .container {
                  }
                  .content {
                    h1{
                        font-size: 32px;
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                  }
                  .bottom-button {
                    font-size: 23px;
                    height: 80px;
                    /* Add any desired styles to the button */
                  }
            }
            .mobile-vibrate{
                top: 76%;
                right: 8px;
                transform: translateY(-50%);
                display: flex;
                .iphone{
                    img{
                        width: 250px;
                    }
                }
                .lines{
                    img{
                        width: 200px;
                        margin-top: 150px;
                    }
                }
            }
        } 
    }
}

@media screen and (max-width: 470px) {
    .home{
        height: auto;
        .home__relative{
            .home__inner{
                display: flex;
                flex-direction: column;
                .container {
                  }
                  .content {
                    h1{
                        font-size: 32px;
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                  }
                  .bottom-button {
                    font-size: 23px;
                    height: 80px;
                    /* Add any desired styles to the button */
                  }
            }
            .mobile-vibrate{
                top: 76%;
                right: 8px;
                transform: translateY(-50%);
                display: flex;
                .iphone{
                    img{
                        width: 250px;
                    }
                }
                .lines{
                    img{
                        width: 150px;
                        margin-top: 150px;
                    }
                }
            }
        } 
    }
}
@media screen and (max-width: 420px) {
    .home{
        height: auto;
        .home__relative{
            .home__inner{
                display: flex;
                flex-direction: column;
                .container {
                  }
                  .content {
                    h1{
                        font-size: 32px;
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                  }
                  .bottom-button {
                    font-size: 23px;
                    height: 80px;
                    /* Add any desired styles to the button */
                  }
            }
            .mobile-vibrate{
                top: 76%;
                right: 8px;
                transform: translateY(-50%);
                display: flex;
                .iphone{
                    img{
                        width: 250px;
                    }
                }
                .lines{
                    img{
                        width: 110px;
                        margin-top: 150px;
                    }
                }
            }
        } 
    }
}
@media screen and (max-width: 400px) {
    .home{
        height: auto;
        .home__relative{
            .home__inner{
                display: flex;
                flex-direction: column;
                .container {
                  }
                  .content {
                    h1{
                        font-size: 28px;
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                  }
                  .bottom-button {
                    font-size: 23px;
                    height: 80px;
                    /* Add any desired styles to the button */
                  }
            }
            .mobile-vibrate{
                top: 76%;
                right: 8px;
                transform: translateY(-50%);
                display: flex;
                .iphone{
                    img{
                        width: 230px;
                    }
                }
                .lines{
                    img{
                        width: 90px;
                        margin-top: 150px;
                    }
                }
            }
        } 
    }
}
@media screen and (max-width: 333px) {
    .home{
        height: auto;
        .home__relative{
            .home__inner{
                display: flex;
                flex-direction: column;
                .container {
                  }
                  .content {
                    h1{
                        font-size: 28px;
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                  }
                  .bottom-button {
                    font-size: 23px;
                    height: 80px;
                    /* Add any desired styles to the button */
                  }
            }
            .mobile-vibrate{
                top: 76%;
                right: 8px;
                transform: translateY(-50%);
                display: flex;
                .iphone{
                    img{
                        width: 210px;
                    }
                }
                .lines{
                    img{
                        width: 80px;
                        margin-top: 150px;
                    }
                }
            }
        } 
    }
}
























